/************************************************

Stylesheet: Custom Stylesheet

*************************************************/

/*********************
BRAND VARIABLES - START
*********************/
$brand-primary: $primary-color;
$brand-secondary: $secondary-color;
$brand-primary-offset: get-color('primary-offset');
$brand-secondary-offset: get-color('secondary-offset');
$brand-negative: get-color('negative');
$brand-primary-gradient: linear-gradient(to right, $brand-primary-offset 0%, $brand-primary 100%);
$brand-primary-gradient-reverse: linear-gradient(to right, $brand-primary 0%, $brand-primary-offset 100%);
$brand-secondary-gradient: linear-gradient(to right, $brand-secondary 0%, $brand-secondary-offset 100%);
$brand-secondary-gradient-reverse: linear-gradient(to right, $brand-secondary-offset 0%, $brand-secondary 100%);
$brand-body-color: $body-font-color;
$brand-header-color: $header-color;
$brand-white: $white;
$brand-off-white: #f5f4ee;
$brand-light-gray: #f7f8f9;
$brand-gray: #ebeff2;
/*********************
BRAND VARIABLES - END
*********************/

/*==============================================*/

/*********************
IMPORTS - START
*********************/
@import 'includes/1_theme_imports';
/*********************
IMPORTS - END
*********************/

/*==============================================*/

/*********************
GENERAL STYLES - START
*********************/
@import 'includes/2_theme_general';
/*********************
GENERAL STYLES - END
*********************/

/*==============================================*/

/*********************
CONTENT STYLES - START
*********************/
@import 'includes/3_theme_content';
/*********************
CONTENT STYLES - END
*********************/

/*==============================================*/

/*********************
FORM STYLES - START
*********************/
@import 'includes/4_theme_form';
/*********************
FORM STYLES - END
*********************/

/*==============================================*/

/*********************
THEME HEADER STYLES - START
*********************/
@import 'includes/5_theme_header';
/*********************
THEME HEADER STYLES - END
*********************/

/*==============================================*/

/*********************
HOMEPAGE STYLES - START
*********************/
body.home,
body.page-template-template-landing-page,
body.page-id-33306, // MANAGED MAAS
body.page-id-32310 // ON-DEMAND MAAS
{
    @include breakpoint(xxlarge)
    {
        header.header
        {
            width: 100%;
            z-index: 999;
            position: absolute;

            .top-bar:not(.is-stuck)
            {
                box-shadow: none;
                background: transparent;
            }
        }
    }
}

#rev_slider_1_1
{
    a.button
    {
        line-height: 1!important;
    }
}

.page-heading 
{
    @include breakpoint(medium)
    {
        font-size: rem-calc(80);
        line-height: 1.2;
    }
}

.hero-heading 
{
    @include breakpoint(medium)
    {
        font-size: rem-calc(60);
        line-height: 1.2;
    }
}

.hero-paragraph
{
    @include breakpoint(medium)
    {
        font-size: rem-calc(30);
        line-height: 1.4;
    }
}

.card
{
    border: none;
    box-shadow: none;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: rem-calc(330);
    border-radius: rem-calc(10);
    background-color: $brand-white;
    border: rem-calc(1) solid $brand-gray;
    padding: rem-calc(45) rem-calc(18) rem-calc(27);

    .card-image
    {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        min-height: rem-calc(107);
        margin-bottom: rem-calc(24);
    }

    @include breakpoint(large)
    {
        border: none!important;
        box-shadow: rem-calc(5) rem-calc(9) rem-calc(60) rgba(58, 58, 58, 0.1)!important;
    }
}

.has-padding
{
    padding: rem-calc(30);

    &-large
    {
        padding: rem-calc(30);

        @include breakpoint(large)
        {
            padding: rem-calc(30) rem-calc(45);
        }
    }
}

.step,
.service
{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: rem-calc(300);
    min-height: rem-calc(375);
    border-radius: rem-calc(10);
    background-color: $brand-white;
    border: rem-calc(1) solid $brand-gray;
    padding: rem-calc(45) rem-calc(18) rem-calc(27);

    @include breakpoint(large)
    {
        border: none;
        box-shadow: rem-calc(5) rem-calc(9) rem-calc(60) rgba(58, 58, 58, 0.1);
    }
}

.step
{
    width: 100%;
    display: block;
    max-width: none;

    .step-count
    {
        text-align: center;
        margin-bottom: rem-calc(27);

        .step-count-inner
        {
            line-height: 1;
            font-weight: 800;
            margin-left: auto;
            text-align: center;
            margin-right: auto;
            color: $brand-white;
            height: rem-calc(90);
            padding: rem-calc(30);
            max-width: rem-calc(90);
            font-size: rem-calc(30);
            border-radius: rem-calc(90);
            font-family: $header-font-family;
            background-color: $brand-primary;

            @include breakpoint(large)
            {
                margin-top: rem-calc(-90);
            }
        }
    }
}

.home_2024-section_4-row
{
    background-color: white;
    border-radius: rem-calc(60);
    filter: drop-shadow(0 0 1.875rem rgba(201,199,189,0.3));
}

.home_2024-section_11-image
{
    @include breakpoint(large)
    {
        margin-right: rem-calc(-45);
    }
}

#portfolio-services
{
    .service
    {
        border: rem-calc(1) solid $brand-gray;
        box-shadow: none;
    }
}

.partner-logos-row
{

    + .partner-logos-row
    {
        margin-top: rem-calc(24);
    }

    .partner-logo
    {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .column:not(:last-child)
    {
        .partner-logo
        {
            margin-bottom: rem-calc(24)!important;

            @include breakpoint(large)
            {
                margin-bottom: 0!important;
            }
        }
    }
}

#team-members
{
    text-align: center;

    .team-member-image
    {
        width: 100%;
        display: table;
        min-height: rem-calc(187);

        .team-member-image-inner
        {
            display: table-cell;
            height: 100%;
        }
    }

    .team-member-description
    {
        .team-member-display-name,
        .team-member-designation
        {
            margin-bottom: 0;
        }

        .team-member-display-name
        {
            + .team-member-designation
            {
                margin-top: rem-calc(8);
            }
        }
    }
}

#home-section_11
{
    .image-arrow
    {
        @include breakpoint(large)
        {
            display: block;
            position: absolute;
            right: rem-calc(-45);
            bottom: 0;
        }

        @include breakpoint(xlarge)
        {
            right: rem-calc(-90);
        }
    }
}

#team-members
{
    .swiper-container
    {
        @include breakpoint(xxlarge)
        {
            .swiper-slide
            {
                max-width: rem-calc(242);
            }
        }
    }
}

#services
{
    .swiper-container
    {
        @include breakpoint(large)
        {
            &.swiper-container-initialized
            {
                overflow: visible!important;
            }
        }

        @include breakpoint(xxlarge)
        {
            .swiper-slide
            {
                opacity: 0;
                transition: 0.3s all;
                max-width: rem-calc(340);
            }

            .swiper-slide-visible
            {
                opacity: 1;
            }
        }

        .service
        {
            margin-bottom: 0!important;
        }
    }
}

#testimonials
{
    text-align: center;

    .swiper-container
    {
        overflow: hidden;
    }

    .testimonial-v2
    {
        > .row
        {
            > .columns.large-10
            {
                background-image: url('../images/backgrounds/testimonials_v2-content-bg.png');
                background-repeat: no-repeat;
                background-position: right;
                // background-size: contain;
            }
        }

        .testimonial-image
        {
            img
            {
                max-width: rem-calc(225);

                @include breakpoint(large)
                {
                    max-width: rem-calc(250);
                }
            }
        }

        .testimonial-author-company-logo
        {
            max-width: rem-calc(150);

            @include breakpoint(large)
            {
                max-width: rem-calc(210);
            }
        }
    }
}

#client-logos,
#client-logos-single-row
{
    .swiper-container
    {
        @include breakpoint(large)
        {
            &.swiper-container-initialized
            {
                overflow: visible!important;
            }
        }

        @include breakpoint(xxlarge)
        {
            .swiper-slide
            {
                opacity: 0;
                transition: 0.3s all;
                max-width: rem-calc(260);
            }

            .swiper-slide-visible
            {
                opacity: 1;
            }
        }

        .client-logo
        {
            width: 100%;
            padding-top: 100%;
            position: relative;
            display: inline-block;

            > a
            {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                position: absolute;
                align-items: center;
                display: inline-flex;
                align-content: center;
                justify-content: center;
                border-radius: rem-calc(10);
                background-color: $brand-white;
                border: rem-calc(1) solid $brand-gray;

                img
                {
                    max-height: rem-calc(110);
                    max-width: rem-calc(204);

                    @include breakpoint(large)
                    {
                        padding: rem-calc(45) rem-calc(24);

                    }
                }

                @include breakpoint(large)
                {
                    border: none!important;
                    box-shadow: rem-calc(5) rem-calc(9) rem-calc(60) rgba(58, 58, 58, 0.1);
                }
            }
        }
    }
}

#client-logos-single-row
{
    .client-logo
    {
        img
        {
            padding: unset !important;
        }
    }
}

#nf-form-1-cont,
#nf-form-2-cont
{
    margin-bottom: rem-calc(30);

    .nf-form-layout
    {
        overflow: hidden;
        border-radius: rem-calc(10);
        background-color: $brand-white;
        border: rem-calc(1) solid $brand-gray;

        @include breakpoint(large)
        {
            border: none;
            box-shadow: rem-calc(5) rem-calc(9) rem-calc(60) rgba(58, 58, 58, 0.1);
        }
    }

    .nf-breadcrumbs,
    .nf-before-form-content
    {
        display: none;
    }

    .nf-form-content
    {
        padding: 0;
    }

    .nf-progress-container
    {
        margin-top: 0;
    }

    .nf-progress,
    .nf-progress-container
    {
        height: rem-calc(5);
    }

    .nf-progress
    {
        background-color: $brand-primary;
    }

    .nf-mp-body
    {
        padding: rem-calc(30);

        .listcheckbox-wrap,
        .list-image-wrap,
        .list-radio-wrap
        {
            text-align: center;
        }

        .list-checkbox-wrap .nf-field-element li, 
        .list-image-wrap .nf-field-element li, 
        .list-radio-wrap .nf-field-element li
        {
            display: inline-block;
        }

        .list-checkbox-wrap .nf-field-element li label, 
        .list-image-wrap .nf-field-element li label, 
        .list-radio-wrap .nf-field-element li label
        {
            display: inline-block;
        }

        .listcheckbox-wrap .nf-field-element ul li input[type="checkbox"], 
        .listradio-wrap .nf-field-element ul li input[type="radio"],
        .listcheckbox-wrap .nf-field-element ul li input[type="checkbox"] + label:before, 
        .listradio-wrap .nf-field-element ul li input[type="radio"] + label:before,
        .listcheckbox-wrap .nf-field-element ul li input[type="checkbox"] + label:after, 
        .listradio-wrap .nf-field-element ul li input[type="radio"] + label:after
        {
            display: none!important;
        }

        .listcheckbox-wrap .nf-field-element ul li input[type="checkbox"] + label, 
        .listradio-wrap .nf-field-element ul li input[type="radio"] + label
        {
            margin-left: 0!important;
            margin-right: rem-calc(10);
            padding: rem-calc(16)!important;
            background-color: $brand-white!important;
            border: rem-calc(1) solid $brand-gray!important;
        }

        .listcheckbox-wrap .nf-field-element ul li input[type="checkbox"]:checked + label, 
        .listradio-wrap .nf-field-element ul li input[type="radio"]:checked + label
        {
            font-weight: 700!important;
            color: $brand-white!important;
            background-color: $brand-primary!important;
        }

        @include breakpoint(large)
        {
            padding: rem-calc(45);
        }
    }

    .submit-wrap
    {
        text-align: center;

        input[type="button"]
        {
            cursor: pointer;
            font-weight: 800;
            position: relative;
            color: $brand-white;
            height: auto!important;
            font-size: rem-calc(15);
            text-transform: uppercase;
            margin-bottom: 0!important;
            border-radius: rem-calc(60);
            background-color: $brand-secondary;
            padding: rem-calc(18) rem-calc(32);

            &:hover,
            &:focus
            {
                color: $brand-white;
                background-color: scale-color($brand-secondary, $lightness: -15%);
            }
        }
    }
    
    .nf-mp-footer
    {
        padding: 0 rem-calc(30) rem-calc(30);

        @include breakpoint(large)
        {
            padding: 0 rem-calc(45) rem-calc(45);
        }
    }

    .nf-next-previous
    {
        margin-top: 0!important;
        margin-bottom: 0!important;

        .nf-previous-item,
        .nf-next-item
        {
            .nf-previous,
            .nf-next
            {
                cursor: pointer;
                font-weight: 800;
                color: $brand-white;
                font-size: rem-calc(12);
                text-transform: uppercase;
                margin-bottom: 0!important;
                border-radius: rem-calc(60);
                background-color: $brand-primary;
                padding: rem-calc(12) rem-calc(24);

                &:hover,
                &:focus
                {
                    color: $brand-white;
                    background-color: scale-color($brand-primary, $lightness: -15%);
                }
            }
        }
    }

    .nf-form-errors
    {
        nf-section
        {
            display: block;
            text-align: center;
            padding: rem-calc(16);
            background-color: $brand-primary;

            .nf-error-msg
            {
                color: $brand-white;
            }
        }
    }
}

.button-container
{
    @include breakpoint(small down)
    {
        .show-inline
        {
            display: block;
            margin-left: auto;
            margin-right: auto;

            + .show-inline
            {
                margin-left: auto;
                margin-top: rem-calc(16);
            }
        }
    }
}

.home-table
{
    border-collapse: collapse;

    @include breakpoint(medium down)
    {
        width: rem-calc(700);
    }

    th 
    {
        color: $black;
        text-align: center;
        font-size: rem-calc(16);

        @include breakpoint(large)
        {
            font-size: rem-calc(18);
            width:rem-calc(300);
        }
    }


    tr 
    {

        &:nth-child(odd)
        {
            background-color: $brand-white !important;

        }

        &:nth-child(even)
        {
            background-color: #f5f4ee !important;

        }

    }

    td 
    {
        text-align: center;
        height: rem-calc(70);

        @include breakpoint(medium)
        {
            height: rem-calc(50);
        }

    }

}

#table-scroll
{
    border: rem-calc(2) solid $brand-white;
    border-radius: rem-calc(30);
    overflow: hidden;
}

// .table-scroll 
// {
//     // width: 750px;
//     overflow-x: scroll;
//     // margin-left: 5em;
//     overflow-y: visible;
//     padding: 0;
//     position: relative;

//     .first-column
//     {
//         &.fixed
//         {
//             position: absolute;
//             // width: 5em;
//             left: 0;
//             top: auto;
//             border-top-width: 2px;
//             margin-top: -1px;

//             &:before 
//             {
//                 content: 'Fixed Row';
//             }
//         }
//     }
// }

// .home-table
// {
//     border-collapse: collapse;

//     th 
//     {
//         color: $black;
//         text-align: center;
//         width:rem-calc(300);
//         font-size: rem-calc(16);

//         @include breakpoint(large)
//         {
//             font-size: rem-calc(18);
//         }
//     }

//     tr 
//     {

//         &:nth-child(odd)
//         {
//             background-color: $brand-white !important;

//         }

//         &:nth-child(even)
//         {
//             background-color: #f5f4ee !important;

//         }

//     }

//     td 
//     {
//         text-align: center;
//         height: rem-calc(70);

//         @include breakpoint(medium)
//         {
//             height: rem-calc(50);
//         }

//     }
// }  


.container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.box {
    flex: 1;
    padding: rem-calc(40);
    min-width: rem-calc(300);
    border-radius: rem-calc(12);
    background-color: $brand-off-white;
}

/*********************
HOMEPAGE STYLES - END
*********************/

/*********************
MaaS PAGE STYLES - START
*********************/
#page_header
{
    border-bottom: rem-calc(1) solid $brand-gray;
}

.maas-box
{
    border: solid rem-calc(1) #D5FCFF;
    border-radius: rem-calc(10);
    padding: rem-calc(60);
    background-color: $brand-white;

    &.section-2-box
    {
        padding: rem-calc(20);

        img 
        {
            height: rem-calc(150);
        }

        @include breakpoint(large)
        {
            .image-with-top-margin
            {
                margin-top: -20%;
            }
        }
    }

    
    @include breakpoint(large)
    {
        .image-with-top-margin
        {
            margin-top: -35%;
        }
    }
    
}

$colors: #00cad9, #fcc301, #ff8700, #ff6c6c, #d05ced, #00cad9;
#process-timeline
{
    counter-reset: numbers;

    .pull-up
    {
        position: relative;
        z-index: 999;
    }

    .timeline-block
    {
        .maas-box
        {
    
            &::after
            {
                height: 50px;
                width: 50px;
                position: absolute;
                right: 95%;
                top: 43%;
                z-index: 2;
                border-radius: 50%; 
                display: flex;   
                justify-content: center;
                align-items: center;
                background-color: #00cad9;
                counter-increment: numbers;
                content: counter(numbers);
                font-size: rem-calc(16);
                color: $brand-white;
                font-weight: bold;
            }
        }

        &:nth-child(even)
        {
            .maas-box
            {
                &::after
                {
                    left: 95%;
                }
            }
        }

        @for $i from 2 through 7 
        {
            &:nth-child(#{$i}) 
            {
                .maas-box::after 
                {
                    background-color: nth($colors, $i - 1);
                }
            }
        }

        @include breakpoint(large down)
        {
            .maas-box::after 
            {
                display: none;
            }
        }
    }

   
}

.timeline-block
{
	// .timeline-year
	// {
	// 	background-color: #3c3c3b;
	// 	padding: rem-calc(12) rem-calc(18);
	// 	position: relative;
    // 	z-index: 1;
	// }

	.timeline-line
	{
		position: relative;

		.timeline-line-inner
		{
			// width: rem-calc(38);
			// height: rem-calc(29);
			// margin: auto;

			&.has-content-left
			{
				// background-image: url(/wp-content/uploads/2017/11/red-arrow-left.png);
				// background-position: center left;
				// background-repeat: no-repeat;
				// background-size: rem-calc(17) rem-calc(29);


                content: '';
                position: absolute;
                width: 300px;
                height: 4px;
               
                right:51%;
                background: $brand-secondary;
                z-index: 1;
			}

			&.has-content-right
			{
                content: '';
                position: absolute;
                width: 300px;
                height: 4px;
               
                left:51%;
                background: $brand-secondary;
                z-index: 1;
			}

			&.has-content-left.has-content-right
			{
				// background-image: url(/wp-content/uploads/2017/11/red-arrow-left.png), url(/wp-content/uploads/2017/11/red-arrow-right.png);
				// background-position: center left, center right;
				// background-repeat: no-repeat, no-repeat;
				// background-size: rem-calc(17) rem-calc(29), rem-calc(18) rem-calc(29);
			}
		}

		@media all and (min-width: rem-calc(1025))
		{
			&::after
			{
				content: " ";
				position: absolute;
				top: 0;
				bottom: 0;
				left: calc(50% - 2px);
				width: rem-calc(4);
				background-color: $brand-secondary;
			}
		}
	}

    .timeline-line-numbers
    {
        height: 50px;
        width: 50px;
        position: absolute;
        right: 95%;
        top: 45%;
        z-index: 2;
        border-radius: 50%; 
        display: none;   
        justify-content: center;
        align-items: center;

        &:nth-child(1)
        {
            background-color: #00cad9;
        }

        &:nth-child(2)
        {
            background-color: #fcc301;
        }
    }
}
.process-step-row
{
    position: relative;
    border-radius: rem-calc(10);
    background-color: $brand-white;
    // border: rem-calc(1) solid $brand-gray;
    // padding: rem-calc(45) rem-calc(18) rem-calc(27);

    .process-step-number
    {
        font-weight: 800;
        color: $brand-white;
        align-items: center;
        width: rem-calc(68);
        height: rem-calc(68);
        display: inline-flex;
        padding: rem-calc(16);
        font-size: rem-calc(24);
        justify-content: center;
        border-radius: rem-calc(10);
        margin-bottom: rem-calc(24);
        background-image: $brand-secondary-gradient;
    }

    &.process-step-row-odd
    {
        @include breakpoint(large)
        {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center right;
            background-image: url('/wp-content/uploads/2021/12/maas-process_step_row_odd-bg.png');

            .process-step-number
            {
                top: 0;
                left: 0;
                border-radius: 0;
                position: absolute;
                border-top-left-radius: rem-calc(10);
            }
        }
    }

    &.process-step-row-even
    {
        @include breakpoint(large)
        {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center left;
            background-image: url('/wp-content/uploads/2021/12/maas-process_step_row_even-bg.png');

            .process-step-number
            {
                top: 0;
                right: 0;
                border-radius: 0;
                position: absolute;
                border-top-right-radius: rem-calc(10);
            }
        }
    }

    @include breakpoint(large)
    {
        padding: 0!important;
        border: none!important;
        min-height: rem-calc(500);
        box-shadow: rem-calc(5) rem-calc(9) rem-calc(60) rgba(58, 58, 58, 0.1)!important;
    }
}
/*********************
MaaS PAGE STYLES - END
*********************/

/*********************
PROCESS PAGE STYLES - START
*********************/
#video-player
{
    padding: rem-calc(16);
    border-radius: rem-calc(10);
    box-shadow: rem-calc(5) rem-calc(9) rem-calc(60) rgba(58, 58, 58, 0.1)!important;
}

#process-section_2
{
    .process-step-row
    {
        border: rem-calc(1) solid $brand-gray;
        padding: rem-calc(45) rem-calc(18) rem-calc(27);
    }
}
/*********************
PROCESS PAGE STYLES - END
*********************/

/*********************
THANK YOU PAGE STYLES - START
*********************/

#thank-you-section_3 img
{
    @include breakpoint(large)
    {
        margin-bottom: rem-calc(-62) !important;
        margin-right: rem-calc(-144);
        margin-top: rem-calc(-76);
    }
}

/*********************
THANK YOU PAGE STYLES - END
*********************/

/*********************
PRICING PAGE STYLES - START
*********************/
#pricing-tabs.pricing-tabs
{
    margin: 0;
    text-align: center;
    list-style-type: none;
    border: transparent;

    .extra-big
    {

        font-size: rem-calc(16);


        @include breakpoint(large)
        {
            font-size: rem-calc(21);
        }
    }

    .big
    {

        font-size: rem-calc(14);

        @include breakpoint(large)
        {
            font-size: rem-calc(18);
        }
    }


    @include breakpoint(large)
    {
        display: flex;
        justify-content: center;

        li
    {
        display: inline-block;
        margin-bottom: rem-calc(12);

       

        a
        {
            font-weight: 800;
            transition: 0.3s all;
            color: $black;
            font-size: rem-calc(15);
            border-radius: rem-calc(60);
            background-color: $brand-white;
            padding: rem-calc(12);
            box-shadow: 5px 9px 60px rgba(58, 58, 58, 0.1);
            border-radius: 26px;

            @include breakpoint(large)
            {
                min-width: rem-calc(270);
               
            

            }

            &:hover,
            &:focus
            {
                color: $brand-white;
                border-color: $brand-secondary;
                background-color: $brand-secondary;
            }
        }

        &.is-active
        {
            a
            {
                color: $brand-white;
                border-color: $brand-secondary;
                background-color: $brand-secondary;
            }
        }

        &:not(:last-child)
        {
            position: relative;
            padding-right: rem-calc(9);
            margin-right: rem-calc(3);

            &::after
            {
                right: 0;
                content: " ";
                display: block;
                top: rem-calc(5);
                position: absolute;
                height: rem-calc(24);
                // border-right: rem-calc(1) solid $brand-primary;
            }
        }
    }
    }
   
    
}

#pricing-tabs.accordion 
{
    .accordion-title
    {
        text-align: left;
        padding: rem-calc(24);
        text-transform: capitalize;
        padding-left: rem-calc(24);
        padding-right: rem-calc(50);
        color: $brand-header-color;
        border-radius: rem-calc(10);
        border: rem-calc(1) solid $brand-gray;

        

        &::before
        {
            display: block;
            content: "\f078";
            right: rem-calc(30);
            color: $brand-secondary;
            font: var(--fa-font-solid);
        }

        &:after
        {
            content: "";
            display: none;
        }
    }

    .accordion-item
    {
        border-radius: rem-calc(10);

        &.is-active
        {
            .accordion-title
            {
                border-radius: 0;
                color: $brand-white;
                border-top-left-radius: rem-calc(10);
                border-top-right-radius: rem-calc(10);

                h4
                {
                    color: $brand-white!important;
                }

                &::before 
                {
                    content: "\f077";
                    color: $brand-white;
                }
            }
        }

        &:not(:last-child)
        {
            margin-bottom: rem-calc(16);
        }

        
    }

        .accordion-content
        {
           padding: rem-calc(8);
    //     border-bottom-left-radius: rem-calc(10);
    //     border-bottom-right-radius: rem-calc(10);
    //     border-left: rem-calc(1) solid $brand-gray;
    //     border-right: rem-calc(1) solid $brand-gray;
    //     border-bottom: rem-calc(1) solid $brand-gray;
        
    
        }
}

#pricing-tabs.tabs
    {
        background: none;   
    }

#pricing_tabs_content.tabs-content
{
    border: transparent;
    background: none;
}

#pricing-section_1
{
    .card
    {
        max-width: 100%;
    }
}

#pricing-table
{
    overflow: hidden;
    background-color: $brand-white;
    border-radius: rem-calc(10)!important;
    border: rem-calc(1) solid $brand-gray;

    tbody, 
    tfoot, 
    thead
    {
        border: none;

        td,
        th
        {
            @include breakpoint(large)
            {
                padding: rem-calc(14) rem-calc(32);
            }
        }
    }

    thead
    {
        background-color: $brand-negative;
    }

    tbody
    {
        border-top: rem-calc(1) solid $brand-gray;
        border-bottom: rem-calc(1) solid $brand-gray;
    }

    @include breakpoint(large)
    {
        border: none;
        box-shadow: rem-calc(5) rem-calc(9) rem-calc(60) rgba(58, 58, 58, 0.1)!important;
    }
}

#home_2023_maas_accordion,
#frequently_asked_questions_accordion
{
    .accordion-title
    {
        text-align: left;
        padding: rem-calc(24);
        text-transform: capitalize;
        padding-left: rem-calc(24);
        padding-right: rem-calc(50);
        color: $brand-header-color;
        border-radius: rem-calc(10);
        border: rem-calc(1) solid $brand-gray;

        @include breakpoint(large)
        {
            border: none;
            padding-left: rem-calc(45);
        }

        &::before
        {
            display: block;
            content: "\f078";
            right: rem-calc(30);
            color: $brand-secondary;
            font: var(--fa-font-solid);
        }

        &:after
        {
            content: "";
            display: none;
        }
    }

    .accordion-item
    {
        border-radius: rem-calc(10);

        &.is-active
        {
            .accordion-title
            {
                border-radius: 0;
                color: $brand-white;
                border-top-left-radius: rem-calc(10);
                border-top-right-radius: rem-calc(10);

                h4
                {
                    color: $brand-white!important;
                }

                &::before 
                {
                    content: "\f077";
                    color: $brand-white;
                }
            }
        }

        &:not(:last-child)
        {
            margin-bottom: rem-calc(16);
        }

        @include breakpoint(large)
        {
            box-shadow: rem-calc(5) rem-calc(9) rem-calc(60) rgba(58, 58, 58, 0.1)!important;
        }
    }

    .accordion-content
    {
        background-color: $brand-white;
        padding: rem-calc(16) rem-calc(24);
        border-bottom-left-radius: rem-calc(10);
        border-bottom-right-radius: rem-calc(10);
        border-left: rem-calc(1) solid $brand-gray;
        border-right: rem-calc(1) solid $brand-gray;
        border-bottom: rem-calc(1) solid $brand-gray;
        
        @include breakpoint(large)
        {
            padding: rem-calc(30) rem-calc(45);
        }
    }
}
/*********************
PRICING PAGE STYLES - END
*********************/

/*********************
PORTFOLIO ARCHIVE STYLES - START
*********************/
ul.filter-tabs
{
    margin: 0;
    text-align: center;
    list-style-type: none;

    li
    {
        display: inline-block;
        margin-bottom: rem-calc(12);

        a
        {
            font-weight: 400;
            transition: 0.3s all;
            display: inline-block;
            color: black;
            font-family: 'Inter';
            text-transform: capitalize;
            font-size: rem-calc(20);
            border-radius: rem-calc(60);
            background-color: transparent;
            padding: rem-calc(10) rem-calc(50);
            border: rem-calc(2) solid #CECCC2;
            background-color: $brand-white;

            &:hover,
            &:focus
            {
                color: black;
                border-color: #00CAD9;
                background-color: #C6F3F6;
            }
        }

        &.active
        {
            a
            {
                color: black;
                border-color: #00CAD9;
                background-color: #C6F3F6;
            }
        }

        &:not(:last-child)
        {
            position: relative;
            padding-right: rem-calc(18);
            margin-right: rem-calc(3);

            // &::after
            // {
            //     right: 0;
            //     content: " ";
            //     display: block;
            //     top: rem-calc(5);
            //     position: absolute;
            //     height: rem-calc(24);
            //     border-right: rem-calc(1) solid $brand-primary;
            // }
        }
    }
}

select.filter-dropdown
{
    max-width: rem-calc(240);
    padding-top: rem-calc(12);
    padding-bottom: rem-calc(12);
    border: rem-calc(2) solid $brand-primary;
    background-color: transparent;
    margin-bottom: 0!important;
}

#portfolio-items, #industries
{
    .portfolio-item
    {
        margin-left: auto;
        margin-right: auto;
        max-width: rem-calc(717);
        border-radius: rem-calc(20);
        border: rem-calc(1) solid #F1F1F1;
        background: #FFF;
        box-shadow: rem-calc(0) rem-calc(4) rem-calc(20) rem-calc(0) rgba(0, 0, 0, 0.08);
        padding: rem-calc(40) 0;

        .portfolio-item-thumbnail
        {
            text-align: center;
            position: relative;
            overflow: hidden;

            .show-for-hover
            {
                position: absolute;
                opacity: 0;
                top: 0;
                left: 0;
                transition: all 0.3s ease-in;
            }
        }

        .portfolio-item-information
        {
           // display: none;
            padding: 0 rem-calc(24);

            .portfolio-item-link
            {
                .button
                {
                    min-width: auto;
                }
            }

            @include breakpoint(large)
            {
                display: block;
            }

            .portfolio-item-categories
            {
                padding-bottom: rem-calc(30);
                padding-top: rem-calc(20);
                
                img 
                {
                    width : rem-calc(38);
                    margin-right : rem-calc(20);
                }
            }
        }

        .portfolio-item-content
        {
            padding: 0 rem-calc(24);
            padding-top: rem-calc(20);
        }

        &:hover
        {
            .portfolio-item-thumbnail
            {
                .show-for-hover
                {
                    opacity: 1;
                }
            }
        }
    }

    @include breakpoint(xlarge)
    {
        .portfolio-item
        {
            margin: rem-calc(1);
        }
    }
}

#portfolio_items_count
{
    padding: rem-calc(30) 0;
}

.portfolio-accordion
{
    .accordion-title
    {
        text-align: left;
        color: #000;
        font-size: rem-calc(24);
        background-color: transparent;
        border: none!important;
        padding-left: rem-calc(30);

        &::after 
        {
            content: "\2b";
            font-family: "Font Awesome 6 Free";
            font-weight: 900;
            font-size: rem-calc(21);
            transition: 0.3s all;
            left: 0;
            position: absolute;
            top: rem-calc(19);

        }
    }

    .is-active
	{
		>a
		{
            color: #000;
            background-color: transparent;

            &::after
			{
                border-width: 0;
                content: "\f068";
                left: 0;			
			}
        }
    }  
    
    .accordion-content
    {
        border-bottom: none!important;
    }
}

/*********************
PORTFOLIO ARCHIVE STYLES - END
*********************/

/*********************
Industries STYLES - START
*********************/

#industries
{
    .button
    {
        padding-right: rem-calc(48);
    }
}

/*********************
Industries STYLES - END
*********************/

/*********************
SINGLE PORTFOLIO PAGE STYLES - START
*********************/
body.single-portfolio
{
    #portfolio_item-header
    {
        background-color: $black;
    }

    #portfolio_item-header-logo
    {
        img
        {
            max-width: rem-calc(290);
        }
    }

    #single-portfolio-header,
    #single-portfolio-categories
    {
        padding: rem-calc(24) 0;
        background-color: $brand-light-gray;
    }

    #single-portfolio-header
    {
        .breadcrumbs
        {
            margin-top: rem-calc(14);
            margin-bottom: 0;
            
            li
            {
                a
                {
                    font-weight: 700;
                }
            }
        }
    }

    #single-portfolio-categories
    {
        .portfolio-categories
        {
            margin-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            
            li
            {
                display: inline-block;
                list-style-type: none;

                a
                {
                    font-weight: 700;
                    font-size: rem-calc(14);
                    color: $brand-secondary;
                    text-transform: uppercase;

                    &:hover
                    {
                        text-decoration: underline;
                    }
                }

                &:not(:last-child)::after
                {
                    opacity: 1;
                    content: "/";
                    position: relative;
                    color: $medium-gray;
                    margin: 0 rem-calc(12);
                }
            }
        }
    }
}



.simplefilter
{
    li
    {
        cursor: pointer;
        padding-top: rem-calc(12) rem-calc(12) rem-calc(20) rem-calc(20);
        border-radius: rem-calc(60);
        margin-bottom: rem-calc(24) !important;
        background-color: $brand-secondary !important;
        transition: background-color .25s ease-out,color .25s ease-out;

        @include breakpoint(large)
        {
            .portfolio-item
            {
                margin-bottom: rem-calc(45) !important;
            }
        }

        &:hover:not(.active), &.active
        {
            background-color: $brand-primary !important;
        }
    }
}
/*********************
SINGLE PORTFOLIO PAGE STYLES - END
*********************/


/*********************
BLOGS STYLES - START
*********************/
.single-post
{
    #inner-content
    {
        h2
        {
            color: $brand-primary;
        }
    }
}

blockquote
{
    color: $black;
    font-weight: 700;
    font-size: rem-calc(18);
    background-color: #e9f4ff;
    border-left: rem-calc(6) solid $brand-primary;
    padding: rem-calc(24) rem-calc(24) rem-calc(30);

    // p
    // {
    //     color: $black;
    //     margin-bottom: 0;

    //     &:not(:last-child)
    //     {
    //         margin-bottom: rem-calc(8);
    //     }
    // }
}

.resource-blockquote
{
    position: relative;

    .resource-blockquote-copy-to-clipboard
    {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: rem-calc(12);
        padding: rem-calc(5);
        text-transform: uppercase;
        background-color: #d7e4ec;
        transition: 0.3s all;

        &:hover
        {
            background-color: #eef2f7;
        }
    }
}

#posts,
#recent-posts
{
    .column:last-child:not(:first-child),
	.columns:last-child:not(:first-child)
	{
		float: left;
	}

	.column:not(:last-child),
	.columns:not(:last-child)
	{
		@include breakpoint(large down)
		{
			.post,
			.service,
			.recent-post
			{
				margin-bottom: rem-calc(30);
			}
		}
	}
    
    .posts-row,
    .services-row
    {
        + .posts-row,
        + .services-row
        {
            margin-top: rem-calc(30);
        }
    }

    .post
    {
        max-width: rem-calc(600);
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0 0 rem-calc(20) rem-calc(1) rgba(176, 191, 200, 0.58);
        border-radius: rem-calc(10);
        background-color: $brand-white;

        .post-thumbnail
        {
            position: relative;

            img
            {
                border-top-right-radius: rem-calc(10);
                border-top-left-radius: rem-calc(10);
            }

            .post-tags
            {
                position: absolute;
                top: rem-calc(16);
                right: rem-calc(16);

                .post-tag
                {
                    display: inline-block;
                    padding: rem-calc(3) rem-calc(6);
                    font-size: rem-calc(14);
                    font-weight: 700;
                    background-color: $brand-white;
                    color: $brand-secondary;
                    text-transform: uppercase;
                    transition: all .3s;

                    &:not(:first-child)
                    {
                        margin-left: rem-calc(2);
                    }

                    &:hover
                    {
                        background-color: $brand-secondary;
                        color: $brand-white;
                    }
                }
            }
        }

        .post-content
        {
            padding: rem-calc(30);

            .post-excerpt
            {
                margin-top: rem-calc(16);
            }

            .post-meta
            {
                font-style: italic;
                line-height: 1.2;
                text-align: center;

                .show-inline
                {
                    + .show-inline
                    {
                        margin-left: rem-calc(8);
                        padding-left: rem-calc(8);
                        border-left: rem-calc(1) solid $brand-body-color;
                    }
                }
            }
        }

        &:hover
        {
            .post-tags
            {
                .post-tag
                {
                    background-color: $brand-secondary;
                    color: $brand-white;
                }
            }
        }
    }
}
/*********************
BLOGS STYLES - END
*********************/

/*********************
SERVICES ARCHIVE STYLES - START
*********************/
#services-posts
{
    .column:last-child:not(:first-child),
	.columns:last-child:not(:first-child)
	{
		float: left;
	}

	.column:not(:last-child),
	.columns:not(:last-child)
	{
		@include breakpoint(large down)
		{
			.service
			{
				margin-bottom: rem-calc(30);
			}
		}
	}

    .service
    {
        max-width: rem-calc(330);
        margin-left: auto;
        margin-right: auto;
    }

    @include breakpoint(xlarge)
    {
        .services-row
        {
            + .services-row
            {
                margin-top: rem-calc(30);
            }
        }
    }
}
/*********************
SERVICES ARCHIVE STYLES - END
*********************/

/*********************
CONTACT US STYLES - START
*********************/

#contact-section_2
{
    .highlighted-number
    {
        position: relative;

        &::before
        {
            content: url(/wp-content/uploads/2023/08/highlight-circle-1.png);
            position: absolute;
            left: rem-calc(-16);
            bottom: rem-calc(-30);
            z-index: -1;
            scale: 0.9;

            @include breakpoint(large down)
            {
                display: none;
            }
        }
    }

    .card
    {
        max-width: unset;
    }
}

/*********************
CONTACT US STYLES - END
*********************/


/**************************
NEW MAAS PAGE STYLES - START
**************************/

.service-box
{
    text-align: center;
    border-radius: rem-calc(30);
    padding: rem-calc(30) rem-calc(24);
    // height: rem-calc(550);
    
    @include breakpoint(large)
    {
        height: auto;
        text-align: inherit;
        padding-left: rem-calc(45);
    }

    .service-image
    {
        @include breakpoint(large)
        {
           // width: 70%;
            margin-top: rem-calc(-65);
            margin-bottom: rem-calc(-65);
        }
    }
    
    &.light-blue-box
    {
        background: linear-gradient(281deg, #00B9C9 1.82%, #00B6C5 29.34%, #02A1B5 51.42%, #01A2B4 67.23%, #00B9C9 91.59%);
      //  background: linear-gradient(145deg, #00CAD9 2.19%, #00BBC9 99.06%), lightgray 0% 0% / 100px 100px repeat;
        //background-blend-mode: multiply;
    }

    &.dark-blue-box
    {
        background: linear-gradient(281deg, #1726FF 1.82%, #2D3CFF 29.34%, #3F51FF 51.42%, #546CFF 67.23%, #8FA3FF 97.48%);
    }

    &.red-box
    {
        background: linear-gradient(281deg, #FF4A4A 1.82%, #FF5151 29.34%, #FF3D3D 51.42%, #FF2B2B 67.23%, #FF5757 91.59%);       // background-blend-mode: multiply;
    }

    &.yellow-box
    {
        background: linear-gradient(282deg, #EA510F 0%, #EF7313 21.42%, #F5930E 47.31%, #F8AD0D 66.4%, #FECD05 90.29%);
    }

    &.green-box
    {
        background: linear-gradient(282deg, #0CAE0F 0%, #1CC020 21.42%, #2BCE2E 47.31%, #40DA44 66.4%, #45E148 90.29%);
    }
}

#new-maas-swiper, #timeline-swiper
{
    .swiper-pagination
    {
        z-index: 1;
        position: relative;

        @include breakpoint(large)
        {
            margin-top: rem-calc(60);
        }
    }
}

.tabs-big-heading
{
    @include breakpoint(large)
    {
        font-size: rem-calc(64);
    }
}

.bg-yellow-gradient
{
    background: linear-gradient(259deg, #FED60C 43.96%, #F9B718 99.37%);
}

.bg-green-gradient
{
    background: linear-gradient(90deg, rgba(0,142,29,1) 0%, rgba(0,169,57,1) 51%, rgba(0,178,68,1) 100%);
}

.bg-blue-gradient
{
    background: linear-gradient(259deg, #00CAD9 43.96%, #17A2AF 99.37%);
}

.new-maas-accordion
{
    .is-active
	{
		>a
		{
            background: $brand-secondary;
        }
    }    
}

.maas-9-row
{
    border-radius: rem-calc(30);

    @include breakpoint(large)
    {
        margin-bottom: rem-calc(-180);
    }
}

.section-9-img
{
    @include breakpoint(large)
    {
        margin-bottom: rem-calc(-60)!important;
        margin-top: rem-calc(-60);
        margin-left: rem-calc(80);
    }
}



#service-tabs.service-tabs
{
    margin: 0;
    text-align: center;
    list-style-type: none;
    border: transparent;

    @include breakpoint(large)
    {
        display: flex;
        justify-content: center;

        li
    {
        display: inline-block;
        margin-bottom: rem-calc(12);

       

        a
        {
            font-weight: 800;
            transition: 0.3s all;
            color: $black;
            font-size: rem-calc(15);
            border-radius: rem-calc(60);
            background-color: #F5F4EE;
            padding: rem-calc(12) rem-calc(30);
           // box-shadow: 5px 9px 60px rgba(58, 58, 58, 0.1);
            border-radius: rem-calc(26);
            margin-right: rem-calc(30);

            @include breakpoint(large)
            {
                min-width: rem-calc(120);
            }

            &:hover,
            &:focus
            {
                color: $brand-white;
                border-color: $brand-secondary;
                background-color: $brand-secondary;
            }
        }

        &.is-active
        {
            a
            {
                color: $brand-white;
                border-color: $brand-secondary;
                background-color: $brand-secondary;
            }
        }

        &:not(:last-child)
        {
            position: relative;
            padding-right: rem-calc(9);
            margin-right: rem-calc(3);

            &::after
            {
                right: 0;
                content: " ";
                display: block;
                top: rem-calc(5);
                position: absolute;
                height: rem-calc(24);
                // border-right: rem-calc(1) solid $brand-primary;
            }
        }
    }
    }
   
    
}

#service-tabs.accordion 
{
    .accordion-title
    {
        text-align: left;
        padding: rem-calc(24);
        text-transform: capitalize;
        padding-left: rem-calc(24);
        padding-right: rem-calc(50);
        color: $brand-header-color;
        border-radius: rem-calc(10);
        border: rem-calc(1) solid $brand-gray;

        

        &::before
        {
            display: block;
            content: "\f078";
            right: rem-calc(30);
            color: $brand-secondary;
            font: var(--fa-font-solid);
        }

        &:after
        {
            content: "";
            display: none;
        }
    }

    .accordion-item
    {
        border-radius: rem-calc(10);

        &.is-active
        {
            .accordion-title
            {
                border-radius: 0;
                color: $brand-white;
                border-top-left-radius: rem-calc(10);
                border-top-right-radius: rem-calc(10);
                background-color: $brand-secondary;

                h4
                {
                    color: $brand-white!important;
                }

                &::before 
                {
                    content: "\f077";
                    color: $brand-white;
                }
            }
        }

        &:not(:last-child)
        {
            margin-bottom: rem-calc(16);
        }

        
    }

        .accordion-content
        {
           padding: rem-calc(8);
    //     border-bottom-left-radius: rem-calc(10);
    //     border-bottom-right-radius: rem-calc(10);
    //     border-left: rem-calc(1) solid $brand-gray;
    //     border-right: rem-calc(1) solid $brand-gray;
    //     border-bottom: rem-calc(1) solid $brand-gray;
        
    
        }
}

#service-tabs.tabs
{
    background: none;   
}

#service-tabs-content.tabs-content
{
    border: transparent;
    background: none;
}

.caret-image
{
    cursor: pointer;
    transform: rotate(180deg);

    &.clicked 
    {
        transform: rotate(0deg);
    }
}

/**************************
NEW MAAS PAGE STYLES - END
**************************/

/**************************
AI COPILOT PAGE STYLES - START
**************************/

.ai-box
{
    background-color: $brand-white;
    padding: rem-calc(40);
    border-radius: rem-calc(30);
}

.ai-second-box
{
    border-radius: rem-calc(30);

    .box-content
    {
        padding: rem-calc(70);
        background-color: #F5F4EE;
        border-bottom-left-radius: rem-calc(30);
        border-bottom-right-radius: rem-calc(30);
    }
}


#wpcf7-f33067-p32838-o1
{
    input:not([type="submit"]),
    input:not([type="button"]),
    input:not(.button),
    textarea,
    select
    {
        border-radius: rem-calc(30);
        border: none;
    }
}

#ai-swiper
{
    .service-box
    {
        height: rem-calc(540);
    }
}
/**************************
AI COPILOT PAGE STYLES - END
**************************/


/**************************
SALES CRM PAGE STYLES - START
**************************/

.sales-box
{
    @include breakpoint(large)
    {
        padding: rem-calc(10);
    }
}




/**************************
SALES CRM PAGE STYLES - END
**************************/

/**************************
ABOUT STYLES - START
**************************/
.text-bg
{
    padding: rem-calc(3) rem-calc(5);
}

.about-box
{
    border-radius: rem-calc(30);
    padding: rem-calc(30);

    &.light-blue-box
    {
        background: linear-gradient(281deg, #00B9C9 1.82%, #00B6C5 29.34%, #02A1B5 51.42%, #01A2B4 67.23%, #00B9C9 91.59%);
     
    }

    &.dark-blue-box
    {
        background: linear-gradient(281deg, #1726FF 1.82%, #2D3CFF 29.34%, #3F51FF 51.42%, #546CFF 67.23%, #8FA3FF 97.48%);
    }

    &.red-box
    {
        background: linear-gradient(281deg, #FF4A4A 1.82%, #FF5151 29.34%, #FF3D3D 51.42%, #FF2B2B 67.23%, #FF5757 91.59%);       // background-blend-mode: multiply;
    }

    &.yellow-box
    {
        background: linear-gradient(282deg, #EA510F 0%, #EF7313 21.42%, #F5930E 47.31%, #F8AD0D 66.4%, #FECD05 90.29%);
    }

}

#timeline-swiper
{
    .timeline 
    {
        margin: 50px 0;
        list-style-type: none;
        display: flex;
        padding: 0;
        text-align: center;

        li 
        {
            transition: all 200ms ease-in;
        }
    }

    // .timestamp 
    // {
    //     .date 
    //     {
    //         position: relative;
    //         z-index: 1000;
    //         color: #FFF;
    //         text-align: center;
    //         font-family: "Inter";
    //         font-size: 24px;
    //         font-style: normal;
    //         font-weight: 700;
    //         line-height: 30px;
    //         top: 17px;
    //         left: -5px;
    //     }

    //     &::after 
    //     {
           
    //     }
    // }

    .status 
    {
        padding: 40px;
        border-top: 10px solid #F5F4EE;
        position: relative;
        transition: all 200ms ease-in;

        span 
        {
            color: #646464;
            text-align: center;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px; 
            padding-top: 20px;          
        }

        &::before
        {
            content: attr(data-content);
            width: 120px;
            height: 56px;
            background-color: #00CAD9;
            border-radius: 30px;
            position: absolute;
            z-index:100;
            top: -5px;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            transition: all 200ms ease-in;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFF;
            text-align: center;
            font-family: "Inter";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
        }
    }
}



/**************************
ABOUT STYLES - END
**************************/

/*********************
LOCATION STYLES - START
*********************/

ul#location-tabs
{
    @include breakpoint(large)
    {
        background-color: #3E3E43;
        display: flex;
        justify-content: center;
        padding: rem-calc(37) 0;
        border: none;
    }

    &.accordion 
    {
        margin-bottom: 0;

        .is-active>a:after 
        {
            display: none;
        }
    }

    .accordion-title
    {
        padding: rem-calc(30);
        color: white;
        background-color: #3E3E43;
    }

    .accordion-content
    {
        padding: rem-calc(40) rem-calc(20);
    }
   

    li 
    {
        a 
        {
            padding: 0 rem-calc(48);
            font-family: 'Inter', sans-serif;
            font-size: rem-calc(24);
            font-weight: 700;
            line-height: rem-calc(30); 
            color: white;
            background-color: unset;
        }

        &.is-active, &:hover
        {
            a 
            {
                color: #F7C12A;
            }
        }
    }
}



.gm-style-iw 
{

    padding: rem-calc(25) rem-calc(50)!important;
    background-color: #363636!important;
    border-radius: rem-calc(30)!important;

    .gm-style-iw-chr
    {
        display: none;
    }

    .gm-style-iw-d
    {
        overflow: auto!important;
    }
}

.gm-style-iw-tc
{
    display: none;
}

.wpcf7-list-item-label
{
    color: white;
}

.page-id-40915
{
    .tabs-content 
    {
        border: none!important;
    }

    .wpcf7-response-output
    {
        color: white;
    }
}

/*********************
LOCATION STYLES - END
*********************/

.service-header
{
    background-image: url("/wp-content/uploads/2024/09/thank-you-page-header-bg.jpg");
}

/*==============================================*/

/*********************
THEME FOOTER STYLES - START
*********************/
@import 'includes/6_theme_footer';
/*********************
THEME FOOTER STYLES - END
*********************/

/*==============================================*/

/*********************
RESPONSIVE STYLES - START
*********************/
@import 'includes/7_theme_responsive';
/*********************
RESPONSIVE STYLES - END
*********************/

/*==============================================*/

/*********************
STRIPE STICKY MENU STYLES - START
*********************/
@import 'includes/8_theme_stripe_sticky_menu';
/*********************
STRIPE STICKY MENU STYLES - END
*********************/
/** 
 * Site Title and Site Description are only shown when there is no logo.
 */
.site-title
{
	margin-bottom: 0;
}

.site-description
{
	color: $dark-gray;
	margin-bottom: 0;
}


/**
 * Adding styles for header and top-bar
 */
header.header
{
}

.top-bar
{
	z-index: 1000 !important;
	position: relative;
	transition: 0.3s all;
	background-color: $brand-white;
	box-shadow: rem-calc(5) rem-calc(9) rem-calc(60) rgba(58, 58, 58, 0.1);

	ul
	{
		padding: 0;
	}

	li.mega-menu-grid
	{
		> .mega-sub-menu
		{
			top: rem-calc(61) !important;
			border-top: rem-calc(1) solid $brand-off-white !important;
		}
	}

	&.is-stuck
	{
		.menu
		{
			a[data-toggle="products-navigation-dropdown"]
			{
				min-height: rem-calc(84);

				img
				{
					transform: translateY(8px);
				}
			}

			li.menu-text
			{
				a
				{
					min-height: rem-calc(84);

					img
					{
						object-fit: cover;
						// width: rem-calc(56);
						width: auto;
						height: rem-calc(56);

						@include breakpoint(medium)
						{
							width: rem-calc(60);
							height: rem-calc(60);
						}
					}
				}
			}
		}

		li.mega-menu-grid
		{
			> .mega-sub-menu
			{
				top: rem-calc(57) !important;
			}
		}
	}
}

/**
 * Off Canvas Styles
 */
#off-canvas,
#off-canvas-products
{
	z-index: 1001;
	padding: rem-calc(60) rem-calc(30) rem-calc(30) rem-calc(60);

	/**
	* Style for when viewport goes below {medium} breakpoint
	*/
	@include breakpoint(small down)
	{
		padding: rem-calc(60) rem-calc(30) rem-calc(30);
	}

	ul.submenu
	{
		@include breakpoint(medium)
		{
			margin-left: rem-calc(18);
		}
	}

	.button,
	button.button
	{
		display: inline-block;
		min-width: rem-calc(175);
		margin-left: auto;
		margin-right: auto;
	}

	ul.social-icons
	{

	}
}

.off-canvas-content
{
	background-color: $brand-white;
	/* 	
	&.is-open-left
	{
		//transform: translateX(300px) translateY(90px)!important;
		opacity: 0.8;
	}
	*/
}

#off-canvas-close,
#off-canvas-products-close
{
	top: 0;
	right: 0;
	outline: none;
	line-height: 1;
	cursor: pointer;
	position: absolute;
	padding: 0 rem-calc(10);
	font-size: rem-calc(60);
	background-color: $brand-white;
}

#mobile-menu-toggle-container
{
	top: 50%;
	width: 100%;
	position: absolute;
	transform: translateY(-51%);
}

#mobile-menu-toggle
{
	right: 0;
	top: 50%;
	line-height: 1;
	position: absolute;
	padding: 0 rem-calc(10);
	font-size: rem-calc(30);
	transform: translateY(-50%);
	font-weight: 800;

	@include breakpoint(medium)
	{
		font-size: rem-calc(36);
	}
}

#mobile-additional-links
{
	line-height: 1;
	padding: 0 rem-calc(10);
	font-size: rem-calc(30);

	a
	{
		display: block;
		line-height: 1;
		border-radius: 0;
		text-align: center;
		transition: 0.3s all;
		color: $brand-primary;
		min-width: rem-calc(36);
		min-height: rem-calc(36);
		padding: 0 0 rem-calc(5);
		background-color: $brand-white;
		border: rem-calc(2) solid $brand-primary;

		i
		{
			line-height: 1;
			padding: rem-calc(0);
			font-size: rem-calc(18);
		}

		&:hover,
		&:focus
		{
			color: $brand-white;
			background-color: $brand-primary;
		}

		&:not(:last-child)
		{
			margin-bottom: rem-calc(8);
		}
	}
}
 
body.logged-in
{
	#off-canvas,
	#off-canvas-products
	{
		// padding-top: rem-calc(105);
	}

	#off-canvas-close,
	#off-canvas-products-close
	{
		// top: rem-calc(46);
	}

	#wpadminbar
	{
		z-index: 1000!important;
	}
}


/** 
 * Removing default styles from .menu and .menu-text (logo)
 * Adding padding to .menu-text (logo)
 */
.menu
{
	a[data-toggle="products-navigation-dropdown"]
	{
		transition: 0.3s all;
		min-height: rem-calc(102);

		img
		{
			transition: 0.3s all;
			transform: translateY(16px);
		}

		&.hover
		{
			background-color: $brand-white;
		}
	}

	#products-navigation-dropdown
	{
		padding: 0;
		border: none;
		// min-width: rem-calc(600);
		box-shadow: rem-calc(5) rem-calc(9) rem-calc(60) rgba(58, 58, 58, 0.1);

		.padded-box
		{
			padding: rem-calc(30);
		}

		.product-navigation-dropdown-menu
		{
			li
			{
				a
				{
					font-weight: 800;
					color: $brand-header-color;
					padding: rem-calc(18) rem-calc(30);

					&.open,
					&:hover
					{
						background-color: $brand-off-white;
					}
				}
			}
		}

		ul.social-icons
		{
			li
			{
				a
				{
					padding: 0;
					color: $brand-body-color;

					&:hover,
					&:focus
					{
						color: $brand-primary;
					}
				}

				&:not(:last-child)
				{
					margin-right: rem-calc(24);
				}
			}
		}

		#products-column
		{
			> .padded-box
			{
				padding: 0;
			}

			.product
			{
				display: flex;
				transition: 0.3s all;
				padding: rem-calc(16) rem-calc(24);

				.product-logo
				{
					min-width: rem-calc(42);
				}

				.product-description
				{
					margin-left: rem-calc(12);
				}

				&:hover
				{
					background-color: rgba($brand-header-color, 0.05);
				}

				&:first-child
				{
					padding-top: rem-calc(24);
				}

				&:last-child
				{
					padding-bottom: rem-calc(24);
				}
			}
		}
	}
	
	li.menu-text
	{
		padding: 0;

		a
		{
			z-index: 1;
			position: relative;
			padding: rem-calc(12) 0;

			img
			{
				object-fit: contain;
				transition: 0.3s all;
				object-position: left;
				width: rem-calc(180);
			 	height: rem-calc(66);

				@include breakpoint(medium)
				{
					object-fit: cover;
					width: rem-calc(222);
				 	height: rem-calc(66);
				}
			}

			@include breakpoint(medium)
			{
				min-height: rem-calc(90);
			}

			// @include breakpoint(xxlarge)
			// {
			// 	padding-left: rem-calc(16);
			// }
		}
	}

	.active
	{
		a
		{
			background: transparent;
		}
	}
}


/**
 * Block Link
 */
.block-link
{
	font-weight: 800;
	transition: 0.3s all;
	font-size: rem-calc(15);
	color: $brand-header-color;

	&:hover
	{
		color: $brand-primary;
	}

	&:not(:last-child)
	{
		margin-right: rem-calc(16);
	}
}

/** 
 * Adding styles for main navigation
 */
#main-nav
{
	display: flex;
	justify-content: center;

	li
	{
		position: relative;
		display: inline-block;
		vertical-align: middle;
		padding-top: rem-calc(6);
		padding-bottom: rem-calc(6);

		a
		{
			padding: 0;
			font-weight: 700;
			transition: 0.3s all;
			font-size: rem-calc(16);
			line-height: rem-calc(30);
			color: $brand-header-color;
			font-family: "Inter", sans-serif;
			
			&:hover
			{
				color: $brand-primary;
			}
		}

		ul.submenu
		{
			overflow: hidden;
			text-align: left;
			width: rem-calc(250);
			background: $brand-white;
			border-radius: rem-calc(10);
			border: rem-calc(1) solid rgba($brand-secondary, 0.15);
			box-shadow: rem-calc(5) rem-calc(9) rem-calc(60) rgba(58, 58, 58, 0.1)!important;

			li
			{
				padding: 0;
				display: block;
				margin-right: 0;

				a
				{
					font-weight: 700;
					transition: 0.3s all;
					padding: rem-calc(8);
					font-size: rem-calc(15);
					line-height: rem-calc(24);
					color: $brand-header-color;
					font-family: "Inter", sans-serif;

					&:hover
					{
						color: $brand-white;
						background-color: $brand-secondary;
					}

					&::after
					{
						display: none;
					}
				}

				&::after
				{
					display: none;
				}

				&:not(:last-child)
				{
					a
					{
						border-bottom: rem-calc(1) solid rgba($brand-secondary, 0.15);
					}
				}

				&.current-menu-item
				{
					a
					{
						color: $brand-white;
						background-color: $brand-primary;
					}

					&::after
					{
						display: none;
					}
				}
			}
		}

		&.is-dropdown-submenu-parent
		{
			>a
			{
				padding-right: rem-calc(18);

				&::after
				{
					right: 0;
					transition: 0.3s all;
					border-color: $brand-header-color transparent transparent;
				}

				&:hover
				{
					&::after
					{
						border-color: $brand-primary transparent transparent;
					}
				}
			}
		}

		&.active
		{
			a
			{
				color: $brand-secondary;

				&::after
				{
					border-color: $brand-secondary transparent transparent;
				}
			}
		}

		&:not(:last-child)
		{
			margin-right: rem-calc(30);
			
			/**
			 * Style for when viewport goes above {large} breakpoint
			 */
			@include breakpoint(large)
			{
				margin-right: rem-calc(48);
			}
		}
	}

	> li
	{
		&::after
		{
			left: 0;
			bottom: 0;
			content: " ";
			display: block;
			position: absolute;
			height: rem-calc(2);
			width: rem-calc(60);
			transition: 0.3s all;
			background-size: 200% 100%;
			background-position: rem-calc(-60) 0;
			background-image: linear-gradient(to right, $brand-primary-offset 0%, $brand-primary 50%, transparent 50%, transparent 100%);
		}

		&.active,
		&:hover,
		&:focus
		{
			&::after
			{
				background-position: 0;
			}
		}
	}
}

/**
 * Off-Canvase Navigation
 */
#offcanvas-nav,
#offcanvas-products-nav
{
	text-align: center;
	margin-top: rem-calc(24);
	margin-bottom: rem-calc(32);

	li
	{
		position: relative;
		padding-top: rem-calc(6);
		padding-bottom: rem-calc(6);

		display: block;
		margin-left: auto;
		margin-right: auto;

		>a
		{
			padding: 0;
			font-weight: 700;
			transition: 0.3s all;
			font-size: rem-calc(20);
			line-height: rem-calc(30);
			color: $brand-white;
			font-family: "Inter", sans-serif;
			display: inline-block;
			
			&:hover
			{
				color: $brand-primary;
			}
		}

		ul.submenu
		{
			padding-top: rem-calc(4);
			padding-bottom: rem-calc(4);
			
			li
			{
				a
				{
					padding: 0;
					font-weight: 700;
					transition: 0.3s all;
					font-size: rem-calc(18);
					line-height: rem-calc(24);
					color: $brand-white;
					font-family: "Inter", sans-serif;

					&:hover
					{
						color: $brand-primary;
					}
				}

				&.active
				{
					a
					{
						color: $brand-primary;
					}
				}

				&:not(:last-child)
				{
					margin-bottom: rem-calc(4);
				}
			}

			@include breakpoint(small)
			{
				margin-left: rem-calc(18);
			}
		}

		&.is-accordion-submenu-parent
		{
			>a
			{
				padding-right: rem-calc(18);

				&::after
				{
    				right: 0;
					transition: 0.3s all;
					border-color: $brand-white transparent transparent;
				}

				&:hover
				{
					&::after
					{
						border-color: $brand-primary transparent transparent;
					}
				}
			}
		}

		&.active
		{
			>a
			{
				color: $brand-secondary;

				&::after
				{
					border-color: $brand-secondary transparent transparent;
				}
			}
		}

		&:not(:last-child)
		{
			margin-bottom: rem-calc(12);
		}
	}

	// > li
	// {
	// 	&::after
	// 	{
	// 		left: 0;
	// 		bottom: 0;
	// 		content: " ";
	// 		display: block;
	// 		position: absolute;
	// 		height: rem-calc(2);
	// 		width: rem-calc(60);
	// 		transition: 0.3s all;
	// 		background-size: 200% 100%;
	// 		background-position: rem-calc(-60) 0;
	// 		background-image: linear-gradient(to right, $brand-primary-offset 0%, $brand-primary 50%, transparent 50%, transparent 100%);
	// 	}

	// 	&.active,
	// 	&:hover,
	// 	&:focus
	// 	{
	// 		&::after
	// 		{
	// 			background-position: 0;
	// 		}
	// 	}
	// }

	// @include breakpoint(small down)
	// {
	// 	text-align: center;
	// }
}

// MEGA MENU
#mega-menu-wrap-main-nav #mega-menu-main-nav
{
	text-align: left;

	> li.mega-menu-item
	{
		position: relative;
		display: inline-block;
		vertical-align: middle;
		padding-top: rem-calc(6);
		padding-bottom: rem-calc(6);

		a.mega-menu-link
		{
			padding: 0;
			font-weight: 700;
			transition: 0.3s all;
			font-size: rem-calc(16);
			line-height: rem-calc(30);
			color: $brand-header-color;
			font-family: "Inter", sans-serif;
			
			&:hover
			{
				color: $brand-primary;
			}
		}

		&.mega-current-menu-item
		{
			> a
			{
				color: $brand-secondary !important;

				&::after
				{
					border-color: $brand-secondary transparent transparent !important;
				}
			}
		}

		ul.mega-sub-menu
		{
			margin-top: rem-calc(6);
		}

		ul.mega-menu-links
		{
			overflow: hidden;
			text-align: left;
			width: rem-calc(250);
			margin-top: rem-calc(6);
			background: $brand-white;
			border-radius: rem-calc(10);
			border: rem-calc(1) solid rgba($brand-secondary, 0.15);
			box-shadow: rem-calc(5) rem-calc(9) rem-calc(60) rgba(58, 58, 58, 0.1)!important;

			li
			{
				padding: 0;
				display: block;
				margin-right: 0;

				a
				{
					font-weight: 700;
					transition: 0.3s all;
					padding: rem-calc(8);
					font-size: rem-calc(15);
					line-height: rem-calc(24);
					color: $brand-header-color;
					font-family: "Inter", sans-serif;

					&:hover
					{
						color: $brand-white;
						background-color: $brand-secondary;
					}

					&::after
					{
						display: none;
					}
				}

				&::after
				{
					display: none;
				}

				&:not(:last-child)
				{
					a
					{
						border-bottom: rem-calc(1) solid rgba($brand-secondary, 0.15);
					}
				}

				&.current-menu-item
				{
					a
					{
						color: $brand-white;
						background-color: $brand-primary;
					}

					&::after
					{
						display: none;
					}
				}
			}
		}

		&:not(:last-child)
		{
			margin-right: rem-calc(24);
			
			/**
			 * Style for when viewport goes above {large} breakpoint
			 */
			@include breakpoint(large)
			{
				margin-right: rem-calc(36);
			}
		}

		&::after
		{
			// left: 0;
			bottom: 0;
			content: " ";
			display: block;
			position: absolute;
			height: rem-calc(2);
			width: rem-calc(60);
			transition: 0.3s all;
			background-size: 200% 100%;
			background-position: rem-calc(-60) 0;
			background-image: linear-gradient(to right, $brand-primary-offset 0%, $brand-primary 50%, transparent 50%, transparent 100%);
		}

		&.active,
		&:hover,
		&:focus
		{
			&::after
			{
				background-position: 0;
			}
		}
	}
}

ul.header-links
{
	display: block;
	margin-left: 0;
	list-style-type: none;

	li
	{
		display: block;
		position: relative;
		vertical-align: middle;

		a
		{
			padding: 0;
			font-weight: 400;
			transition: 0.3s all;
			display: inline-block;
			font-size: rem-calc(16);
			line-height: rem-calc(21);
			color: $brand-header-color !important;
			border-bottom: rem-calc(1) solid transparent;
			
			&:hover
			{
				color: $brand-primary !important;
				border-bottom-color: $brand-secondary !important;
			}
		}

		&:not(:last-child)
		{
			margin-right: rem-calc(0);
			margin-bottom: rem-calc(3);
		}
	}
}

.header-product-row
{
	.product
	{
		display: flex;

		.product-logo
		{
			min-width: rem-calc(42);
		}

		.product-description
		{
			margin-left: rem-calc(12);
		}
	}
}
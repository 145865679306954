/** 
 * Add styles for body
 */
body
{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.row
{
	width: 100%;
}

.row.w-100
{
	max-width: 100%;
}

.row.w-70
{
	max-width: 70%;
}


/** 
 * [data-href]
 */
*[data-href]
{
	cursor: pointer;
}


/** 
 * Add font sizes
 */
.extra-large
{
	font-size: rem-calc(24);
}

.extra-big
{
	font-size: rem-calc(21);
}

.big
{
	font-size: rem-calc(18);
}

.small
{
	font-size: rem-calc(14);
	line-height: rem-calc(24);
}

.extra-small
{
	font-size: rem-calc(12);
	line-height: rem-calc(21);
}


/** 
 * Add float classes
 */
.float-left-for-large
{
	@include breakpoint(large)
	{
		float: left!important;
	}
}

.float-right-for-large
{
	@include breakpoint(large)
	{
		float: right!important;
	}
}

.float-left-for-xlarge
{
	@include breakpoint(xlarge)
	{
		float: left!important;
	}
}

.float-right-for-xlarge
{
	@include breakpoint(xlarge)
	{
		float: right!important;
	}
}


/** 
 * Add margin classes
 */

.margin-0
{
	margin: 0!important;
}

.no-margin-bottom
{
	margin-bottom: 0;
}

.bottom-buffer-0point5rem
{
	margin-bottom: rem-calc(8);
}

.bottom-buffer-1rem
{
	margin-bottom: rem-calc(16);
}

.bottom-buffer-1point5rem
{
	margin-bottom: rem-calc(24)!important;
}

.bottom-buffer-2rem
{
	margin-bottom: rem-calc(32);
}

.bottom-buffer-2point5rem
{
	margin-bottom: rem-calc(40);
}


/** 
 * Add font weight classes
 */
.font-normal
{ 
	font-weight: 400;
}

.font-bold
{
	font-weight: 700;
}

.font-extrabold
{
	font-weight: 800;
}


/** 
 * Add background position and size classes
 */
.bg-center
{
	background-position: center center!important;
}

.bg-center-top
{
	background-position: center top!important;
}

.bg-center-bottom
{
	background-position: center bottom!important;
}

.bg-size-cover
{
	background-size: cover!important;
}

.bg-size-w100
{
	background-size: 100% auto!important;
}

.bg-no-repeat
{
	background-repeat: no-repeat;
}

.bg-repeat-x
{
	background-repeat: repeat-x;
}

.bg-attachment-fixed
{
	/**
	* Style for when viewport goes above {large} breakpoint
	*/
	@include breakpoint(large)
	{
		background-attachment: fixed;
	}
}


/** 
 * Add text and background color classes
 */
.alt-primary
{
	color: $brand-primary;
}

.alt-secondary
{
	color: $brand-secondary;
}

.alt-white
{
	color: $brand-white;
}

.alt-black
{
	color: $black;
}

.alt-header-color
{
	color: $brand-header-color;
}

.bg-primary
{
	background-color: $brand-primary;
}

.bg-primary-gradient
{
	background-image: $brand-primary-gradient;
}

.bg-primary-gradient-reverse
{
	background-image: $brand-primary-gradient-reverse;
}

.bg-secondary
{
	background-color: $brand-secondary;
}

.bg-secondary-gradient
{
	background-image: $brand-secondary-gradient;
}

.bg-secondary-gradient-reverse
{
	background-image: $brand-secondary-gradient-reverse;
}

.bg-negative
{
	background-color: $brand-negative;
}

.bg-white
{
	background-color: $brand-white;
}

.bg-off-white
{
	background-color: $brand-off-white;
}

.bg-timeline
{
	background: linear-gradient(90deg, hsla(185, 54%, 85%, 1) 0%, hsla(41, 80%, 88%, 1) 100%);
}

.bg-primary-for-mobile
{
	/**
	* Style for when viewport goes below {large} breakpoint
	*/
	@include breakpoint(large down)
	{
		background-color: $brand-primary;
	}
}

.bg-primary-offset-for-mobile
{
	/**
	* Style for when viewport goes below {large} breakpoint
	*/
	@include breakpoint(large down)
	{
		background-color: $brand-primary-offset;
	}
}

.bg-secondary-for-mobile
{
	/**
	* Style for when viewport goes below {large} breakpoint
	*/
	@include breakpoint(large down)
	{
		background-color: $brand-secondary;
	}
}

.bg-secondary-offset-for-mobile
{
	/**
	* Style for when viewport goes below {large} breakpoint
	*/
	@include breakpoint(large down)
	{
		background-color: $brand-secondary-offset;
	}
}

.bg-negative-for-mobile
{
	/**
	* Style for when viewport goes below {large} breakpoint
	*/
	@include breakpoint(large down)
	{
		background-color: $brand-negative;
	}
}

.bg-offwhite-half-vertical
{
	background: linear-gradient(to right, $brand-white 0%, $brand-off-white 100%);

	@media all and #{breakpoint(large)}
	{
		background: linear-gradient(to right, $brand-white 50%, $brand-off-white 50%);
	}
}

.bg-secondary-half-vertical
{
	background: $brand-secondary;

	@media all and #{breakpoint(large)}
	{
		background: linear-gradient(to right, $brand-white 50%, #00CAD9 50%, #00BBC9 100%);
	}
}

/** 
 * Add text-transform and text-decoration classes
 */
.uc
{
	text-transform: uppercase;
}

.underlined
{
	text-decoration: underline;
}

.capitalize
{
	text-transform: capitalize;
}

/** 
 * Add inline classes
 */
.show-inline
{
	display: inline-block;
	vertical-align: middle;

	+ .show-inline
	{
		margin-left: rem-calc(16);
	}
}

/** 
 * Add shadow classes
 */

 .has-shadow-around
 {
	box-shadow: rem-calc(5) rem-calc(9) rem-calc(60) rgba(58, 58, 58, 0.1);
 }

 /** 
 * Add border sizes
 */
 .has-border-radius
 {
	border-radius: rem-calc(30);
 }
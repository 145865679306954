/*********************
IMPORT FONTAWESOME - START
*********************/
$fa-font-path: "../fonts/font-awesome";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
/*********************
IMPORT FONTAWESOME - END
*********************/

/*********************
IMPORT FOUNDATION DATEPICKER - START
*********************/
@import "~foundation-datepicker/scss/foundation-datepicker.scss";

.datepicker
{
	td
	{
		&.active.day, 
		&.active.year,
		span.active
		{
			background: $brand-primary;
			color: $brand-white;
		}

		&.day:not(.disabled):hover, 
		&.year:not(.disabled):hover,
		span:not(.disabled):hover
		{
			background-color: $brand-secondary;
			color: $brand-white;
		}
	}
}

.codesm-datepicker-field
{
	cursor: pointer!important;
}
/*********************
IMPORT FOUNDATION DATEPICKER - END
*********************/

/*********************
IMPORT INTL-TEL-INPUT - START
*********************/
$flagsImagePath: "../images/intl-tel-input/";
@import '~intl-tel-input/src/css/intlTelInput.scss';

.iti
{
	display: block;
	margin-bottom: rem-calc(16)!important;

	.iti__flag-container
	{
		left: rem-calc(1);
		top: rem-calc(1);
		bottom: rem-calc(1);
		padding: 0 0 0 rem-calc(1);
		outline: none;

		.iti__selected-flag
		{
			background-color: #eeeeee;
			border-right: rem-calc(1) solid #e0e0e0;
			outline: none;
		}
	}
}
/*********************
IMPORT INTL-TEL-INPUT - END
*********************/

/*********************
IMPORT SWIPER - START
*********************/
@import '~swiper/css';
// @import '~swiper/css/navigation';
// @import '~swiper/css/pagination';
@import '~swiper/css/grid';

.swiper-pagination
{
    margin-left: auto;
    margin-right: auto;
    margin-top: rem-calc(24);
	position: relative;
	text-align: center;
    bottom: 0!important;
    clear: both;

    .swiper-pagination-bullet
    {
        width: rem-calc(12);
		height: rem-calc(12);
		cursor: pointer;
        display: inline-block;
        border-radius: 100%;
        background: $brand-secondary-offset;
        opacity: 1;

        &:focus
        {
            outline: none;
        }

        &:not(:last-child)
        {
            margin-right: rem-calc(10);
        }
    }

    .swiper-pagination-bullet-active
    {
        background: $brand-primary;
    }

	&:not(.swiper-pagination-bullets-dynamic)
	{
		width: 100%!important;
	}

	&.swiper-pagination-bullets-dynamic
	{
		font-size: 0;
		overflow: hidden;
		margin-left: auto;
		margin-right: auto;
		white-space: nowrap;

		.swiper-pagination-bullet
		{
			position: relative;
			transition: 0.3s all;
			transform: scale(0.33);

			&-active
			{
				transform: scale(1);

				&-main
				{
					transform: scale(1);
				}

				&-prev
				{
					transform: scale(0.66);

					&-prev
					{
						transform: scale(0.33);
					}
				}

				&-next
				{
					transform: scale(0.66);

					&-next
					{
						transform: scale(0.33);
					}
				}
			}
		}
	}
}
/*********************
IMPORT SWIPER - END
*********************/

/*********************
IMPORT OWL CAROUSEL - START
*********************/
@import '~owl.carousel/src/scss/owl.carousel.scss';
@import '~owl.carousel/src/scss/owl.theme.default.scss';

.owl-carousel
{
	.owl-item
	{
		img
		{
			display: inline-block;
			width: auto!important;
		}
	}
}

.owl-theme
{
	.owl-dots 
	{
		.owl-dot
		{
			span
			{
				height: rem-calc(10);
				width: rem-calc(10);
				background-color: $brand-secondary-offset;
				border: none;
				border-radius: rem-calc(10);
				margin: rem-calc(3);
				margin-top: rem-calc(10);
				transition: 0.3s all;
			}

			&.active,
			&:hover
			{
				span
				{
					background-color: $brand-primary;
				}
			}
		}
	}
}

#custom-owl-carousel
{
    .item
    {
        text-align: center;
    }
}

#custom-owl-carousel-thumbs-container
{
    margin-top: rem-calc(24);
    margin-bottom: rem-calc(16)!important;
	position: relative;

    #custom-owl-carousel-thumbs
    {
        .owl-stage
        {
            margin: 0 auto;
        }

        .owl-item
        {
            transition: 0.3s all;

            .owl-thumb-item
            {
                cursor: move;
            }

            img
            {
                display: block;
                width: 100%!important;
            }
            
            &:not(.current)
            {
                opacity: 0.5;
            }
        }
    }
    
    .carousel-controls
    {
        margin-top: rem-calc(8);
    }
}
/*********************
IMPORT OWL CAROUSEL - END
*********************/

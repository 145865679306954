/** 
 * Add label styles
 */
label
{
	font-size: rem-calc(16);
	color: $brand-body-color;
}

/** 
 * Add input styles
 */
input:not([type="submit"]),
input:not([type="button"]),
input:not(.button),
textarea,
select
{
	resize: none;
	height: auto;
	outline: none;
	font-weight: 400;
	box-shadow: none;
	transition: 0.3s all;
	padding: rem-calc(15);
	font-size: rem-calc(16);
	color: $brand-body-color;
	line-height: rem-calc(24);
	background-color: #ffffff;
	//border-radius: rem-calc(10);
	margin: 0 0 rem-calc(16)!important;
    //border: rem-calc(1) solid $brand-primary;
}

select
{
	padding-right: rem-calc(30);
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: %23ff8800;'></polygon></svg>");
}

input:not([type="submit"]):focus,
input:not([type="button"]):focus,
input:not(.button):focus,
textarea:focus,
select:focus
{
	outline: none;
	box-shadow: none;
    border-image: none;
	color: $brand-header-color;
	background-color: #f1f1f1;
    border: rem-calc(1) solid $brand-secondary;
}

input::placeholder, 
textarea::placeholder
{
	color: $brand-body-color;
	opacity: 1;
}

.wpcf7-list-item
{
    display: block!important;
    margin-left: 0!important;

    label
    {
        cursor: pointer;
    }
}

.wpcf7 .row .row
{
	margin-left: -0.5rem!important;
	margin-right: -0.5rem!important;
}

.wpcf7 .row .row .columns
{
	padding-left: 0.5rem!important;
	padding-right: 0.5rem!important;
}

.wpcf7 button[type="submit"]
{
	margin-bottom: 0;
}

input.wpcf7-not-valid,
select.wpcf7-not-valid,
textarea.wpcf7-not-valid
{
	border: rem-calc(1) solid red!important;
}

nf-rows-wrap
{
	.nf-row
	{
		.nf-field-container
		{
			margin-bottom: 0!important;

			input:not([type="submit"]),
			input:not([type="button"]),
			input:not(.button),
			textarea,
			select
			{
				margin: 0!important;
			}

			.checkbox-wrap .nf-field-element label.nf-checked-label:before, 
			.checkbox-wrap .nf-field-label label.nf-checked-label:before, 
			.listcheckbox-wrap .nf-field-element label.nf-checked-label:before, 
			.listcheckbox-wrap .nf-field-label label.nf-checked-label:before
			{
				color: $brand-primary;
			}
		}

		&:not(:last-child)
		{
			margin-bottom: rem-calc(16)!important;
		}
	}
}

.ajax-loader
{
	display: none;
}

span.wpcf7-not-valid-tip
{
	display: none;
}

div.wpcf7-response-output
{
	margin: 0;
	margin-top: 1rem;
	text-align: center;
}

.iti--allow-dropdown
{
	width: 100%;
}

.iti__selected-flag
{
	border-top-left-radius: rem-calc(10);
	border-bottom-left-radius: rem-calc(10);
}

#wpcf7-f17590-p17516-o1
{
	input:not(.button), input:not([type=button]), input:not([type=submit]), select, textarea
	{
		border: none;
		border-radius: 0;
	}
}
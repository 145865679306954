/**
* Style for when viewport goes below {xxlarge} breakpoint
*/
@media all and #{breakpoint(xlarge down)}
{
	.top-bar-left
	{
		// text-align: center;

		&.float-left
		{
			float: none!important;
		}

		.menu
		{
			display: block;

			.menu-text
			{
				justify-content: center;

				a
				{
					display: inline-block;
					// img
					// {
					// 	max-width: rem-calc(195)!important;
					// }
				}
			}
		}
	}

	.top-bar-right
	{
		width: 100%;

		.float-right
		{
			float: none!important;
			display: block;
			text-align: center;
		}

		.show-inline
		{
			display: block;
			+ .show-inline
			{
				margin-left: 0;
			}
		}

		.primary-menu
		{
			margin-top: rem-calc(16);
			margin-bottom: rem-calc(16);
		}

		.additional-links
		{
			margin-bottom: rem-calc(8);
		}
	}
}

/**
* Style for when viewport goes above {xlarge} breakpoint
*/
@media all and #{breakpoint(xlarge)}
{
	.padding-left-for-xlarge
	{
		padding-left: rem-calc(15);
	}

	.padding-right-for-xlarge
	{
		padding-right: rem-calc(15);
	}

	.extra-padding-left-for-xlarge
	{
		padding-left: rem-calc(30);
	}

	.extra-padding-right-for-xlarge
	{
		padding-right: rem-calc(30);
	}

	.extra-big-padding-left-for-xlarge
	{
		padding-left: rem-calc(45);
	}

	.extra-big-padding-right-for-xlarge
	{
		padding-right: rem-calc(45);
	}

	.extra-large-padding-left-for-xlarge
	{
		padding-left: rem-calc(60);
	}

	.extra-large-padding-right-for-xlarge
	{
		padding-right: rem-calc(60);
	}
}

/**
* Style for when viewport goes above {large} breakpoint
*/
@media all and #{breakpoint(large)}
{
	.padding-left-for-large
	{
		padding-left: rem-calc(15);
	}

	.padding-right-for-large
	{
		padding-right: rem-calc(15);
	}

	.extra-padding-left-for-large
	{
		padding-left: rem-calc(30);
	}

	.extra-padding-right-for-large
	{
		padding-right: rem-calc(30);
	}

	.extra-big-padding-left-for-large
	{
		padding-left: rem-calc(45);
	}

	.extra-big-padding-right-for-large
	{
		padding-right: rem-calc(45);
	}

	.extra-large-padding-left-for-large
	{
		padding-left: rem-calc(60);
	}

	.extra-large-padding-right-for-large
	{
		padding-right: rem-calc(60);
	}
}

/**
* Style for when viewport goes below {medium} breakpoint
*/
@media all and #{breakpoint(medium down)}
{
	div.hide-bg,
	section.hide-bg
	{
		background-image: none !important;
	}

	div.hide-bg.columns
	{
		.vc_column-inner
		{
			background-image: none !important;
		}
	}

	.remove-br br
	{
		display: none;
	}

	.collapse-medium
	{
		margin-left: 0 !important;
    	margin-right: 0 !important;
	}
}

/**
* Style for when viewport goes below {small} breakpoint
*/
@media all and #{breakpoint(small down)}
{
	html,
	body
	{
		font-size: 92%!important;
	}
}
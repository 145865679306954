/** 
 * Add min-height to #content
 */
#content
{
	min-height: rem-calc(300);
}

.w-100
{
	width: 100%;
}

.content-width
{
	.column-left
	{
		.column-left-content
		{
			padding-left: rem-calc(15);
			padding-right: rem-calc(15);

			@include breakpoint(xlarge)
			{
				width: 100%;
				max-width: rem-calc(720);
				padding-left: rem-calc(15);
				padding-right: rem-calc(45);
			}
		}
	}

	.column-right
	{
		.column-right-content
		{
			padding-left: rem-calc(15);
			padding-right: rem-calc(15);

			@include breakpoint(xlarge)
			{
				width: 100%;
				max-width: rem-calc(720);
				padding-right: rem-calc(15);
				padding-left: rem-calc(45);
			}
		}
	}

	.content-image
	{
		width: 100%;
	}
}

.clear
{
	&-left
	{
		clear: left;
	}

	&-right
	{
		clear: right;
	}
}


/** 
 * Remove margin-bottom from flex-video
 */
.flex-video
{
	margin-bottom: 0;
}


/** 
 * Add scroll-to-top arrow style
 */
a#scroll-to-top
{
	background: url('../images/arrow.png') no-repeat center center;
	background-size: cover;
	z-index: 999;
	width: rem-calc(35);
	height: rem-calc(35);
	position: fixed;
	right: rem-calc(30);
	bottom: rem-calc(30);
	text-indent: -9999px;
	font-size: 0;
	cursor: pointer;
	outline: 0;

    /**
    * Style for when viewport goes below {small} breakpoint
    */
    @include breakpoint(small down)
    {
		right: 0;
    	bottom: 0;
    }
}


/** 
 * Adjust visual composer class styles and add padding classes
 */
.wpb_button, .wpb_content_element, ul.wpb_thumbnails-fluid>li
{
	margin-bottom: 0;
}

.vc_col-has-fill>.vc_column-inner, 
.vc_row-has-fill+.vc_row-full-width+.vc_row>.vc_column_container>.vc_column-inner, 
.vc_row-has-fill+.vc_row>.vc_column_container>.vc_column-inner, 
.vc_row-has-fill+.vc_vc_row>.vc_row>.vc_vc_column>.vc_column_container>.vc_column-inner, 
.vc_row-has-fill+.vc_vc_row_inner>.vc_row>.vc_vc_column_inner>.vc_column_container>.vc_column-inner, 
.vc_row-has-fill>.vc_column_container>.vc_column-inner, 
.vc_row-has-fill>.vc_row>.vc_vc_column>.vc_column_container>.vc_column-inner, 
.vc_row-has-fill>.vc_vc_column_inner>.vc_column_container>.vc_column-inner, 
.vc_section.vc_section-has-fill, 
.vc_section.vc_section-has-fill+.vc_row-full-width+.vc_section, 
.vc_section.vc_section-has-fill+.vc_section
{
	padding-top: 0;
}

.no-padding-foundation .columns
{
	padding-left: 0;
	padding-right: 0;
}

.no-padding-vc .vc_column_container>.vc_column-inner
{
	padding-left: 0!important;
	padding-right: 0!important;
}


/** 
 * Add button styles
 */
.button,
button.button
{
	&.rounded
	{
		border-radius: rem-calc(60);
	}

	&.has-icon
	{
		text-align: left;
		position: relative;
		padding-right: rem-calc(52);

		&::after
		{
			top: 50%;
			display: flex;
			content: "\f054";
			position: absolute;
			color: $brand-white;
			align-items: center;
			right: rem-calc(19);
			transition: 0.3s all;
			align-content: middle;
			justify-content: center;
			transform: translateY(-50%);
			font: var(--fa-font-solid);
			font-size: rem-calc(14);
		}

		@include breakpoint(large)
		{
			min-width: rem-calc(270);
		}
	}

	&.white
	{
		color: $black;
		background-color: $brand-white;

		&.has-icon
		{
			&::after
			{
				color: $black;
			}
		}

		&.alt-primary
		{
			color: $brand-primary;

			&.has-icon
			{
				&::after
				{
					color: $brand-primary;
				}
			}

			&.has-border
			{
				border: rem-calc(1) solid $brand-primary;
			}
		}

		&.alt-secondary
		{
			color: $brand-secondary;

			&.has-icon
			{
				&::after
				{
					color: $brand-secondary;
				}
			}

			&.has-border
			{
				border: rem-calc(1) solid $brand-secondary;
			}
		}

		&:hover
		{
			background-color: $brand-off-white;
		}
	}

	&.gradient-button
	{
		background: $brand-primary-gradient;
		border-radius: rem-calc(30);
		width: rem-calc(255);
	}

	&.button-with-gradient-border
	{
		position: relative;
		color: $brand-white;
		display: inline-block;
		border-radius: rem-calc(50);
		background-color: transparent;
		border: rem-calc(2) solid transparent;

		&::before
		{
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: "";
			z-index: -1;
			border: inherit;
			position: absolute;
			border-radius: inherit;
			mask-composite: exclude;
			background: $brand-primary-gradient border-box;
			mask: linear-gradient(white, white) padding-box, linear-gradient(white, white);
		}

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
		{
			border: 0;
			color: $brand-white;
			background: $brand-primary-gradient;

			&::before
			{
				display: none;
			}
		}

		@-moz-document url-prefix()
		{
			border: 0;
			color: $brand-white;
			background: $brand-primary-gradient;

			&::before
			{
				display: none;
			}
		}
	}

	&.full-width-button
	{
		width: 100%;
	}
}


/** 
 * Add list classes
 */
ul.list-icon
{
	li
	{
		padding-left: rem-calc(15);
	}

	&-cross
	{
		list-style-image: url('../images/list-icons/list-icon-cross.svg');
	}

	&-check
	{
		margin-left: 0;

		li 
		{
			background:  url('../images/list-icons/list-icon-check.svg');
			background-repeat: no-repeat;
			background-position: top rem-calc(5) left;
			background-size: rem-calc(22) rem-calc(22);
			padding: rem-calc(5) rem-calc(0) rem-calc(4) rem-calc(35);
			list-style: none;
			margin: 0;			
		}
	}

	&-circle
	{
		&-primary
		{
			list-style-image: url('../images/list-icons/list-icon-circle-primary.svg');
		}
	}
}

ul.social-icons
{
    list-style-type: none;
    margin: 0;

    li
	{
        display: inline-block;
        margin-right: rem-calc(36);

        a
		{
            color: $dark-gray;
            font-size: rem-calc(20);
            transition: all 0.3s;

            &:hover,
            &:focus 
			{
                color: $brand-white;
            }
        }

        &:last-child 
		{
            margin-right: 0;
        }
    }
}


/**
 * Add Accordion Styles
 */
.accordion
{
	border: none;
	text-align: center;
	background: transparent;

	.accordion-title
	{
		font-weight: bold;
		text-align: center;
		color: $brand-white;
		padding: rem-calc(18);
		font-size: rem-calc(16);
		background-color: $brand-white;

		&::before
		{
			display: none;
		}
	}

	.is-active
	{
		>a
		{
			outline: none;
			position: relative;
			color: $brand-white;
			background: $brand-primary;

			&::after
			{
				width: 0;
				height: 0;
				content: " ";
				position: absolute;
				border-style: solid;
				bottom: rem-calc(-15);
				left: calc(50% - 25px);
				border-width: rem-calc(15) rem-calc(25) 0 rem-calc(25);
				border-color: $brand-primary transparent transparent transparent;
			}
		}
	}
}
 
.accordion-content
{
	border: none;
	background: transparent;
	padding: rem-calc(16) rem-calc(24);

	@include breakpoint(large)
	{
		padding: rem-calc(30) rem-calc(45);
	}
}


/** 
 * Add pagination styles
 */
.pagination
{
	clear: both;
	position: relative;
	text-align: center;
	margin-bottom: 0;

	span,
	a
	{
		border: none;
		font-weight: 400;
		text-align: center;
		border-radius: 50%;
		color: $brand-white;
		transition: all 0.3s;
		text-decoration: none;
		margin: 0 rem-calc(5);
		display: inline-block;
		font-size: rem-calc(18);
		min-width: rem-calc(51);
		min-height: rem-calc(51);
		line-height: rem-calc(21);
		background-repeat: no-repeat;
		font-family: "Inter", sans-serif;
		background-position: center center;
		background-color: $brand-secondary;
		padding: rem-calc(15) rem-calc(12) rem-calc(15);
	}

	a:hover,
	span.active
	{
		border-color: none;
		color: $brand-white;
		background-color: $brand-primary;
	}
}
/** 
 * Site footer and footer widget areas
 */
footer.footer
{
    margin-top: 0;
    background-color: $brand-negative;

    #inner-footer
    {
        padding: rem-calc(45) 0;

        .row
        {
            + .row
            {
                margin-top: rem-calc(30);

                @include breakpoint(large)
                {
                    margin-top: rem-calc(60);
                }
            }
        }

        @include breakpoint(large)
        {
            padding: rem-calc(105) 0;
        }
    }

    #footer-bottom
    {
        padding: rem-calc(24) 0;
        border-top: rem-calc(1) solid rgba($brand-white, 0.1);

        @include breakpoint(large)
        {
            padding: rem-calc(30) 0;
        }
    }

    .widgettitle
    {
        color: $brand-white;
	}

    #footer-links
    {
        display: block;
        line-height: 1;

        li
        {
            display: block;
            margin-right: 0;
            margin-bottom: rem-calc(12);

            a
            {
                padding: 0;
                line-height: 1;
                font-weight: 800;
                color: $dark-gray;
                transition: 0.3s all;
                display: inline-block;
                font-size: rem-calc(14);
                text-transform: uppercase;
                font-family: "Inter", sans-serif;

                &:hover
                {
                    color: $brand-white;
                }
            }

            &:last-child
            {
                margin-bottom: 0;
            }

            @include breakpoint(medium)
            {
                margin-bottom: 0;
                display: inline-block;
                margin-right: rem-calc(48);

                &:last-child
                {
                    margin-right: 0;
                }
            }
        }
    }

    .footer-links
    {
        display: block;
        margin-left: 0;
        list-style-type: none;

        li
        {
            display: block;
            position: relative;
            vertical-align: middle;

            a
            {
                padding: 0;
                font-weight: 400;
                color: $brand-white;
                transition: 0.3s all;
                display: inline-block;
                font-size: rem-calc(18);
                line-height: rem-calc(21);
                border-bottom: rem-calc(1) solid transparent;
                
                &:hover
                {
                    color: $brand-secondary;
                    border-bottom-color: $brand-secondary;
                }
            }

            &:not(:last-child)
            {
                margin-right: rem-calc(0);
                margin-bottom: rem-calc(3);
            }
        }
    }

    .brand-logos-row
    {
        .brand-logo
        {
            margin-left: auto;
            margin-right: auto;
            text-align: center;

            img
            {
                height: rem-calc(62);
            }
        }

        .column:not(:last-child)
        {
            .brand-logo
            {
                margin-bottom: rem-calc(24);

                @include breakpoint(large)
                {
                    margin-bottom: 0;
                }
            }
        }
    }

    #copyright
    {
        p
        {
            line-height: 1;
            color: #787878;
            font-size: rem-calc(15);
        }
    }
}

.wider-row
{
    @include breakpoint(xxlarge)
    {
        min-width: rem-calc(1480);
    }
}